nav{
  display:flex;
  justify-content: space-between;
}
.content-container {
  height: 80vh;
  margin-top: 2.55%;
  margin-right: 10%;
  margin-left: 10%;
  border-radius: 70px;
  background-color: #f8f8ff;
  display: flex;
  justify-content: center;
}